/*******************************************************************************
API - Plugin - Scroll to Top
*******************************************************************************/

#return-to-top {
    z-index: 999;
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: none;
    background-color: #00ada8;
    opacity: .5;
    transition: all .3s ease-in-out;
}

#return-to-top:hover {
    opacity: 1;
    bottom: 60px;
    color: #ffffff;
    box-shadow: 0 2px 10px 0 #666666;
}

#return-to-top i {
    position: relative;
    left: 11px;
    top: 7px;
    font-size: 2em;
    color: #e6e6e6;
}

/*******************************************************************************
API - Plugin - Spinner
*******************************************************************************/

#spinner {
    display: none;
    background: rgba(255, 255, 255, 0.7);
    color: #666666;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
}

#spinner .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 125px;
    height: 100px;
    font-size: 25px;
    margin-top: -50px;
    /* Half the height */
    margin-left: -63px;
    /* Half the width */
}

#spinner .loader>div {
    background-color: #333;
    height: 100%;
    width: 10px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

#spinner .loader .rect-1 {}

#spinner .loader .rect-2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

#spinner .loader .rect-3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

#spinner .loader .rect-4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

#spinner .loader .rect-5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1.0);
    }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}